enum RolesEnum {
    SUPER_ADMIN_ROLE = 2,
    REVIEWER_ROLE = 3,
    SUPER_VISOR_ROLE = 4,
    BANK_PORTAL_ROLE = 5,
    DATA_ENTRY_ROLE = 6,
    SALES_ROLE = 7,
    SALES_INSURANCE_ROLE = 8,
    MICRO_FINANCE_SALES_PERSON = 9,
    MICRO_FINANCE_ROLE = 10,
    FREE_AGENT_ROLE = 11,
    MARKETING_ROLE = 12,
    CARE_PLUS_ROLE = 14,
}

export default RolesEnum
