import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { toggleNavbar } from "src/redux/Navbar/action"
import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { BreadCrumbs } from "src/components/Shared"
import { FC, lazy } from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"

const Navbar = lazy(() => import("src/layouts/DashboardLayout/Navbar"))
const Sidebar = lazy(() => import("src/layouts/DashboardLayout/Sidebar"))
const Footer = lazy(() => import("src/layouts/DashboardLayout/Footer"))

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        paddingBottom: "0px !important",
    },
    rootLoading: {
        width: "100%",
        height: "100vh",
        backgroundColor: theme.palette.primary.light,
        zIndex: 1205,
        position: "fixed",
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 0),
        backgroundColor: "#f1f5f9",
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        ...theme.mixins.toolbar,
    },
    container: {
        backgroundColor: "#f1f5f9",
        padding: theme.spacing(6),
        [theme.breakpoints.down("md")]: {
            borderRadius: "0px",
            padding: theme.spacing(2),
        },
        paddingBottom: "0px",
    },
}))

const DashboardLayout: FC<{ children: FC }> = (props: { children: FC }) => {
    const { children } = props
    const classes = useStyles()
    const { navbar, loading } = useSelector((state: { navbar: { open: boolean }; loading: { loading: boolean } }) => ({ navbar: state.navbar, loading: state.loading }), shallowEqual)

    const dispatch = useDispatch()

    const handlebarNavbar = () => {
        dispatch(toggleNavbar())
    }

    return (
        <>
            {loading.loading && (
                <Grid container justifyContent="center" alignItems="center" className={classes.rootLoading}>
                    <CircularProgress color="primary" size={120} />
                </Grid>
            )}
            <Grid className={classes.root} style={{ backgroundColor: "#f1f5f9" }}>
                <Navbar open={navbar.open} handleDrawerToggle={handlebarNavbar} />
                <Sidebar open={navbar.open} handleDrawerToggle={handlebarNavbar}>
                    {children}
                </Sidebar>
                <main className={classes.content}>
                    <Box className={classes.toolbar} />
                    <Box className={classes.container}>
                        <Box minHeight={700}>{children}</Box>
                        <Footer />
                    </Box>
                </main>
            </Grid>
        </>
    )
}

export default DashboardLayout
