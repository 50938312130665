import { UserAuthAction, UserAuthLogoutAction, UserUpdateLangAction } from "src/redux/Auth/types"
import { Dispatch } from "redux"
import { Auth } from "src/redux/Auth/reducer"
import { deleteServices } from "src/api/services"

export const AUTH_LOGIN = "AUTH_LOGIN"
export const AUTH_LOGOUT = "AUTH_LOGOUT"
export const SET_AUTH_LANG = "SET_AUTH_LANG"

const authLoginAction = ({ authToken, user }: Auth): UserAuthAction => ({
    type: AUTH_LOGIN,
    payload: { authToken, user },
})

const updateFullNameAction = ({ lang }: { lang: string }): UserUpdateLangAction => ({
    type: SET_AUTH_LANG,
    payload: { lang },
})

const authLogoutAction = (): UserAuthLogoutAction => ({
    type: AUTH_LOGOUT,
})

export const authLogin =
    ({ authToken, user }: Auth) =>
    async (dispatch: any) =>
        dispatch(authLoginAction({ authToken, user }))

export const authLogout = () => async (dispatch: Dispatch) => {
    try {
        await deleteServices(`/auth/logout`)
            .then(() => {
                dispatch(authLogoutAction())
            })
            .catch(() => {
                dispatch(authLogoutAction())
            })
    } catch (err) {
        dispatch(authLogoutAction())
        console.error(err)
    }
}
export const authClearAll = () => async (dispatch: Dispatch) => {
    dispatch(authLogoutAction())
}

export const updateAuthLang =
    ({ lang }: { lang: string }) =>
    async (dispatch: any) =>
        dispatch(updateFullNameAction({ lang }))
