import { LookupAction, RemoveLookupAction } from "src/redux/Lookups/types"
import store from "src/redux/store"
import { getServices } from "src/api/services"
import ProductIDEnum from "src/enum/Product-ID.enum"

export const ROLES = "ROLES"
export const BANKS = "BANKS"
export const PRODUCT_NAMES = "PRODUCT_NAMES"
export const PRODUCT_TYPES = "PRODUCT_TYPES"
export const EMPLOYEE_TYPES = "EMPLOYEE_TYPES"
export const EMPLOYEE_TYPES_HOME_LOAN = "EMPLOYEE_TYPES_HOME_LOAN"
export const SALARY_TRANSFER_STATUSES = "SALARY_TRANSFER_STATUSES"
export const INDUSTRIES = "INDUSTRIES"
export const COMPANY_SECTORS = "COMPANY_SECTORS"
export const CITIES = "CITIES"
export const AREAS = "AREAS"
export const CITIES_AREAS = "CITIES_AREAS"
export const DOCUMENT_VALIDATES = "DOCUMENT_VALIDATES"
export const PERSONAL_LOAN_DOCUMENTS = "PERSONAL_LOAN_DOCUMENTS"
export const HOME_LOAN_DOCUMENTS = "HOME_LOAN_DOCUMENTS"
export const CAR_LOAN_DOCUMENTS = "CAR_LOAN_DOCUMENTS"
export const CAR_TYPES = "CAR_TYPES"
export const CARD_RATE_TYPES = "CARD_RATE_TYPES"
export const DEPOSIT_RATE_TYPES = "DEPOSIT_RATE_TYPES"
export const CURRENCIES = "CURRENCIES"
export const PAYMENT_FREQUENCIES = "PAYMENT_FREQUENCIES"
export const CARD_PRODUCT_TYPES = "CARD_PRODUCT_TYPES"
export const PROVIDER_TYPES = "PROVIDER_TYPES"
export const GENDER = "GENDER"
export const ADMIN_APPLICATION_STATUSES = "ADMIN_APPLICATION_STATUSES"
export const REWARD_TYPES = "REWARD_TYPES"
export const BONUS_TYPES = "BONUS_TYPES"
export const OCCUPATIONS = "OCCUPATIONS"
export const COMPANIES = "COMPANIES"
export const AGES = "AGES"
export const CAR_BRANDS = "CAR_BRANDS"
export const CAR_PLANS = "CAR_PLANS"
export const CAR_CHINES_NON_CHINES = "CAR_CHINES_NON_CHINES"
export const CAR_MODELS = "CAR_MODELS"
export const INSURANCE_PRODUCT_TYPES = "INSURANCE_PRODUCT_TYPES"
export const INSURANCE_APPLICATION_STATUS = "INSURANCE_APPLICATION_STATUS"
export const CAR_MODEL_YEARS = "CAR_MODEL_YEARS"
export const LABELS = "LABELS"
export const AGENTS = "AGENTS"
export const LEADS_CRITERIA_STATUSES = "LEADS_CRITERIA_STATUSES"
export const MEDICAL_PLANS = "MEDICAL_PLANS"
export const SALES_PERSONS = "SALES_PERSONS"
export const APPLICATION_REJECTION_REASONS = "APPLICATION_REJECTION_REASONS"
export const OTHER_INCOME_SOURCE = "OTHER_INCOME_SOURCE"
export const COMPANIES_PRODUCT_TYPES = "COMPANIES_PRODUCT_TYPES"
export const APPLICATION_TYPES = "APPLICATION_TYPES"
export const REMOVE_ROLES = "REMOVE_ROLES"
export const REMOVE_BANKS = "REMOVE_BANKS"
export const REMOVE_PRODUCT_NAMES = "REMOVE_PRODUCT_NAMES"
export const REMOVE_PRODUCT_TYPES = "REMOVE_PRODUCT_TYPES"
export const REMOVE_EMPLOYEE_TYPES = "REMOVE_EMPLOYEE_TYPES"
export const REMOVE_EMPLOYEE_TYPES_HOME_LOAN = "REMOVE_EMPLOYEE_TYPES_HOME_LOAN"
export const REMOVE_SALARY_TRANSFER_STATUSES = "REMOVE_SALARY_TRANSFER_STATUSES"
export const REMOVE_INDUSTRIES = "REMOVE_INDUSTRIES"
export const REMOVE_COMPANY_SECTORS = "REMOVE_COMPANY_SECTORS"
export const REMOVE_CITIES = "REMOVE_CITIES"
export const REMOVE_AREAS = "REMOVE_AREAS"
export const REMOVE_CITIES_AREAS = "REMOVE_CITIES_AREAS"
export const REMOVE_DOCUMENT_VALIDATES = "REMOVE_DOCUMENT_VALIDATES"
export const REMOVE_PERSONAL_LOAN_DOCUMENTS = "REMOVE_PERSONAL_LOAN_DOCUMENTS"
export const REMOVE_HOME_LOAN_DOCUMENTS = "REMOVE_HOME_LOAN_DOCUMENTS"
export const REMOVE_CAR_LOAN_DOCUMENTS = "REMOVE_CAR_LOAN_DOCUMENTS"
export const REMOVE_CAR_TYPES = "REMOVE_CAR_TYPES"
export const REMOVE_CARD_RATE_TYPES = "REMOVE_CARD_RATE_TYPES"
export const REMOVE_DEPOSIT_RATE_TYPES = "REMOVE_DEPOSIT_RATE_TYPES"
export const REMOVE_CURRENCIES = "REMOVE_CURRENCIES"
export const REMOVE_PAYMENT_FREQUENCIES = "REMOVE_PAYMENT_FREQUENCIES"
export const REMOVE_CARD_PRODUCT_TYPES = "REMOVE_CARD_PRODUCT_TYPES"
export const REMOVE_PROVIDER_TYPES = "REMOVE_PROVIDER_TYPES"
export const REMOVE_GENDER = "REMOVE_GENDER"
export const REMOVE_ADMIN_APPLICATION_STATUSES = "REMOVE_ADMIN_APPLICATION_STATUSES"
export const REMOVE_REWARD_TYPES = "REMOVE_REWARD_TYPES"
export const REMOVE_BONUS_TYPES = "REMOVE_BONUS_TYPES"
export const REMOVE_OCCUPATIONS = "REMOVE_OCCUPATIONS"
export const REMOVE_COMPANIES = "REMOVE_COMPANIES"
export const REMOVE_AGES = "REMOVE_AGES"
export const REMOVE_CAR_BRANDS = "REMOVE_CAR_BRANDS"
export const REMOVE_CAR_PLANS = "REMOVE_CAR_PLANS"
export const REMOVE_CAR_CHINES_NON_CHINES = "REMOVE_CAR_CHINES_NON_CHINES"
export const REMOVE_CAR_MODELS = "REMOVE_CAR_MODELS"
export const REMOVE_INSURANCE_PRODUCT_TYPES = "REMOVE_INSURANCE_PRODUCT_TYPES"
export const REMOVE_INSURANCE_APPLICATION_STATUS = "REMOVE_INSURANCE_APPLICATION_STATUS"
export const REMOVE_CAR_MODEL_YEARS = "REMOVE_CAR_MODEL_YEARS"
export const REMOVE_LABELS = "REMOVE_LABELS"
export const REMOVE_AGENTS = "REMOVE_AGENTS"
export const REMOVE_LEADS_CRITERIA_STATUSES = "REMOVE_LEADS_CRITERIA_STATUSES"
export const REMOVE_MEDICAL_PLANS = "REMOVE_MEDICAL_PLANS"
export const REMOVE_SALES_PERSONS = "REMOVE_SALES_PERSONS"
export const REMOVE_APPLICATION_REJECTION_REASONS = "REMOVE_APPLICATION_REJECTION_REASONS"
export const REMOVE_OTHER_INCOME_SOURCE = "REMOVE_OTHER_INCOME_SOURCE"
export const REMOVE_MEDICAL_CARD_SERVICES_TYPE = "REMOVE_MEDICAL_CARD_SERVICES_TYPE"
export const REMOVE_COMPANIES_PRODUCT_TYPES = "REMOVE_COMPANIES_PRODUCT_TYPES"
export const REMOVE_APPLICATION_TYPES = "REMOVE_APPLICATION_TYPES"
export const REMOVE_ALL_LOOKUPS = "REMOVE_ALL_LOOKUPS"

const getLookupAction = ({ type, payload }): LookupAction => ({ type, payload })
export const removeLookupAction = ({ type }): RemoveLookupAction => ({ type })

export const getRoles = () => (dispatch) => {
    getServices(`/lookups/roles`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: ROLES, payload: res.data }))
    })
}
export const getProviderTypes = () => (dispatch) => {
    getServices(`/lookups/providerTypes`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: PROVIDER_TYPES, payload: res.data }))
    })
}
export const getBanks = () => (dispatch) => {
    getServices(`/lookups/banks`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: BANKS, payload: res.data }))
    })
}
export const getProductNames = () => (dispatch) => {
    getServices(`/lookups/productNames`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: PRODUCT_NAMES, payload: res.data }))
    })
}
export const getProductTypes = () => (dispatch) => {
    getServices(`/lookups/bankingProductTypes`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: PRODUCT_TYPES, payload: res.data }))
    })
}
export const getEmployeeTypes = () => (dispatch) => {
    getServices(`/lookups/employeeTypes`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: EMPLOYEE_TYPES, payload: res.data }))
    })
}
export const getEmployeeTypesHomeLoan = () => (dispatch) => {
    getServices(`/lookups/employeeTypes?productTypeId=${ProductIDEnum.HOME_LOAN_PRODUCT_ID}`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: EMPLOYEE_TYPES_HOME_LOAN, payload: res.data }))
    })
}
export const getSalaryTransferStatuses = () => (dispatch) => {
    getServices(`/lookups/salaryTransferStatuses`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: SALARY_TRANSFER_STATUSES, payload: res.data }))
    })
}
export const getCities = () => (dispatch) => {
    getServices(`/lookups/cities`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: CITIES, payload: res.data }))
    })
}
export const getAreas = () => (dispatch) => {
    getServices(`/lookups/areas`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: AREAS, payload: res.data }))
    })
}
export const citiesAreas = () => (dispatch) => {
    getServices(`/lookups/citiesAndAreas`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: CITIES_AREAS, payload: res.data }))
    })
}
export const getDocumentValidates = () => (dispatch) => {
    getServices(`/lookups/documentValidities`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: DOCUMENT_VALIDATES, payload: res.data }))
    })
}
export const getPersonalLoanDocuments = () => (dispatch) => {
    getServices(`/lookups/documents/1`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: PERSONAL_LOAN_DOCUMENTS, payload: res.data }))
    })
}
export const getHomeLoanDocuments = () => (dispatch) => {
    getServices(`/lookups/documents/2`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: HOME_LOAN_DOCUMENTS, payload: res.data }))
    })
}
export const getCarLoanDocuments = () => (dispatch) => {
    getServices(`/lookups/documents/3`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: CAR_LOAN_DOCUMENTS, payload: res.data }))
    })
}
export const getCarTypes = () => (dispatch) => {
    getServices(`/lookups/carTypes`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: CAR_TYPES, payload: res.data }))
    })
}
export const getCardRateTypes = () => (dispatch) => {
    getServices(`/lookups/cardRateTypes`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: CARD_RATE_TYPES, payload: res.data }))
    })
}
export const getDepositRateTypes = () => (dispatch) => {
    getServices(`/lookups/depositRateTypes`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: DEPOSIT_RATE_TYPES, payload: res.data }))
    })
}
export const getCurrencies = () => (dispatch) => {
    getServices(`/lookups/currencies`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: CURRENCIES, payload: res.data }))
    })
}
export const getPaymentFrequencies = () => (dispatch) => {
    getServices(`/lookups/paymentFrequencies`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: PAYMENT_FREQUENCIES, payload: res.data }))
    })
}
export const getCardProductTypes = () => (dispatch) => {
    getServices(`/lookups/bankingProductTypes/2`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: CARD_PRODUCT_TYPES, payload: res.data }))
    })
}
export const getGender = () => (dispatch) => {
    getServices(`/lookups/genders`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: GENDER, payload: res.data }))
    })
}
export const getRewardTypes = () => (dispatch) => {
    getServices(`/lookups/rewardTypes`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: REWARD_TYPES, payload: res.data }))
    })
}
export const getBonusTypes = () => (dispatch) => {
    getServices(`/lookups/bonusTypes`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: BONUS_TYPES, payload: res.data }))
    })
}
export const getAdminApplicationStatuses = () => (dispatch) => {
    getServices(`/lookups/adminApplicationStatuses`).then((res: { data: any }) => {
        dispatch(getLookupAction({ type: ADMIN_APPLICATION_STATUSES, payload: res.data }))
    })
}
export const getOccupations = () => (dispatch) => {
    getServices(`/lookups/occupations`).then((res) => {
        dispatch(getLookupAction({ type: OCCUPATIONS, payload: res.data }))
    })
}
export const getCompanies = () => (dispatch) => {
    getServices(`/lookups/companies`).then((res) => {
        dispatch(getLookupAction({ type: COMPANIES, payload: res.data }))
    })
}
export const getIndustries = () => (dispatch) => {
    getServices(`/lookups/industries`).then((res) => {
        dispatch(getLookupAction({ type: INDUSTRIES, payload: res.data }))
    })
}
export const getCompanySectors = () => (dispatch) => {
    getServices(`/lookups/companySectors`).then((res) => {
        dispatch(getLookupAction({ type: COMPANY_SECTORS, payload: res.data }))
    })
}
export const getAges = () => (dispatch) => {
    getServices(`/lookups/ages`).then((res) => {
        dispatch(getLookupAction({ type: AGES, payload: res.data }))
    })
}
export const getCarBrands = () => (dispatch) => {
    getServices(`/lookups/carBrands`).then((res) => {
        dispatch(getLookupAction({ type: CAR_BRANDS, payload: res.data }))
    })
}
export const getCarModels = () => (dispatch) => {
    getServices(`/lookups/carModels`).then((res) => {
        dispatch(getLookupAction({ type: CAR_MODELS, payload: res.data }))
    })
}
export const getCarChinesNonChineseCarBrands = () => (dispatch) => {
    getServices(`/lookups/chinesNonChineseCarBrands`).then((res) => {
        dispatch(getLookupAction({ type: CAR_CHINES_NON_CHINES, payload: res?.data?.carBrands }))
    })
}
export const getCarPlans = () => (dispatch) => {
    getServices(`/lookups/carInsurancePlans`).then((res) => {
        dispatch(getLookupAction({ type: CAR_PLANS, payload: res.data }))
    })
}
export const getInsuranceProductTypes = () => (dispatch) => {
    getServices(`/lookups/insuranceProductTypes`).then((res) => {
        dispatch(getLookupAction({ type: INSURANCE_PRODUCT_TYPES, payload: res.data }))
    })
}
export const getInsuranceApplicationStatus = () => (dispatch) => {
    getServices(`/lookups/insuranceApplicationStatuses`).then((res) => {
        dispatch(getLookupAction({ type: INSURANCE_APPLICATION_STATUS, payload: res.data }))
    })
}
export const getCarModelsYears = () => (dispatch) => {
    getServices(`/lookups/carModelYears`).then((res) => {
        dispatch(getLookupAction({ type: CAR_MODEL_YEARS, payload: res.data }))
    })
}
export const getLabels = () => (dispatch) => {
    getServices(`/whatsAppMessages/labels`).then((res) => {
        dispatch(getLookupAction({ type: LABELS, payload: res.data.data }))
    })
}
export const getAgents = () => (dispatch) => {
    getServices(`/whatsAppMessages/agents`).then((res) => {
        dispatch(getLookupAction({ type: AGENTS, payload: res.data.data }))
    })
}

export const getMedicalPlans = () => (dispatch) => {
    getServices(`lookups/medicalInsurancePlans`).then((res) => {
        dispatch(getLookupAction({ type: MEDICAL_PLANS, payload: res.data }))
    })
}

export const getSalePersons = () => (dispatch) => {
    getServices(`lookups/salesPersons`).then((res) => {
        dispatch(getLookupAction({ type: SALES_PERSONS, payload: res.data }))
    })
}

export const getAllLeadCriteriaStatuses = () => (dispatch) => {
    getServices(`lookups/leadCriteriaStatuses`).then((res) => {
        dispatch(getLookupAction({ type: LEADS_CRITERIA_STATUSES, payload: res.data }))
    })
}

export const getAllRejectionReasons = () => (dispatch) => {
    getServices(`lookups/faydetyRejectedReasons`).then((res) => {
        dispatch(getLookupAction({ type: APPLICATION_REJECTION_REASONS, payload: res.data }))
    })
}

export const getOtherIncomeSource = () => (dispatch) => {
    getServices(`lookups/otherIncomeSources`).then((res) => {
        dispatch(getLookupAction({ type: OTHER_INCOME_SOURCE, payload: res.data }))
    })
}

export const getCompaniesProductTypes = () => (dispatch) => {
    getServices(`lookups/companiesProductTypes`).then((res) => {
        dispatch(getLookupAction({ type: COMPANIES_PRODUCT_TYPES, payload: res.data }))
    })
}

export const getInsuranceApplicationTypes = () => (dispatch) => {
    getServices(`lookups/insuranceApplicationTypes`).then((res) => {
        dispatch(getLookupAction({ type: APPLICATION_TYPES, payload: res.data }))
    })
}

export const dispatchAllLookups: any = (dispatch) => {
    const lookupsState = store.getState().lookups
    const authState = store.getState().auth
    if (lookupsState?.roles && lookupsState?.roles.length === 0) {
        dispatch(getRoles())
    }
    if (lookupsState?.providerTypes && lookupsState?.providerTypes.length === 0) {
        dispatch(getProviderTypes())
    }
    if (lookupsState?.banks && lookupsState?.banks.length === 0) {
        dispatch(getBanks())
    }
    if (lookupsState?.productNames && lookupsState?.productNames.length === 0) {
        dispatch(getProductNames())
    }
    if (lookupsState?.productTypes && lookupsState?.productTypes.length === 0) {
        dispatch(getProductTypes())
    }
    if (lookupsState?.employeeTypes && lookupsState?.employeeTypes.length === 0) {
        dispatch(getEmployeeTypes())
    }
    if (lookupsState?.employeeTypesHomeLoan && lookupsState?.employeeTypesHomeLoan.length === 0) {
        dispatch(getEmployeeTypesHomeLoan())
    }
    if (lookupsState?.salaryTransferStatuses && lookupsState?.salaryTransferStatuses.length === 0) {
        dispatch(getSalaryTransferStatuses())
    }
    if (lookupsState?.industries && lookupsState?.industries.length === 0) {
        dispatch(getIndustries())
    }
    if (lookupsState?.companySectors && lookupsState?.companySectors.length === 0) {
        dispatch(getCompanySectors())
    }
    if (lookupsState?.cities && lookupsState?.cities.length === 0) {
        dispatch(getCities())
    }
    if (lookupsState?.areas && lookupsState?.areas.length === 0) {
        dispatch(getAreas())
    }
    if (lookupsState?.citiesAreas && lookupsState?.citiesAreas.length === 0) {
        dispatch(citiesAreas())
    }
    if (lookupsState?.documentValidates && lookupsState?.documentValidates.length === 0) {
        dispatch(getDocumentValidates())
    }
    if (lookupsState?.personalLoanDocuments && lookupsState?.personalLoanDocuments.length === 0) {
        dispatch(getPersonalLoanDocuments())
    }
    if (lookupsState?.homeLoanDocuments && lookupsState?.homeLoanDocuments.length === 0) {
        dispatch(getHomeLoanDocuments())
    }
    if (lookupsState?.carLoanDocuments && lookupsState?.carLoanDocuments.length === 0) {
        dispatch(getCarLoanDocuments())
    }
    if (lookupsState?.carTypes && lookupsState?.carTypes.length === 0) {
        dispatch(getCarTypes())
    }
    if (lookupsState?.cardRateTypes && lookupsState?.cardRateTypes.length === 0) {
        dispatch(getCardRateTypes())
    }
    if (lookupsState?.depositRateTypes && lookupsState?.depositRateTypes.length === 0) {
        dispatch(getDepositRateTypes())
    }
    if (lookupsState?.currencies && lookupsState?.currencies.length === 0) {
        dispatch(getCurrencies())
    }
    if (lookupsState?.paymentFrequencies && lookupsState?.paymentFrequencies.length === 0) {
        dispatch(getPaymentFrequencies())
    }
    if (lookupsState?.cardProductTypes && lookupsState?.cardProductTypes.length === 0) {
        dispatch(getCardProductTypes())
    }
    if (lookupsState?.providerTypes && lookupsState?.providerTypes.length === 0) {
        dispatch(getProviderTypes())
    }
    if (lookupsState?.gender && lookupsState?.gender.length === 0) {
        dispatch(getGender())
    }
    if (lookupsState?.rewardTypes && lookupsState?.rewardTypes.length === 0) {
        dispatch(getRewardTypes())
    }
    if (lookupsState?.bonusTypes && lookupsState?.bonusTypes.length === 0) {
        dispatch(getBonusTypes())
    }
    if (authState?.authToken && lookupsState?.adminApplicationStatuses && lookupsState?.adminApplicationStatuses.length === 0) {
        dispatch(getAdminApplicationStatuses())
    }
    if (lookupsState?.occupations && lookupsState?.occupations.length === 0) {
        dispatch(getOccupations())
    }
    if (lookupsState?.companies && lookupsState?.companies.length === 0) {
        dispatch(getCompanies())
    }
    if (lookupsState?.ages && lookupsState?.ages.length === 0) {
        dispatch(getAges())
    }
    if (lookupsState?.carBrands && lookupsState?.carBrands.length === 0) {
        dispatch(getCarBrands())
    }
    if (lookupsState?.carPlans && lookupsState?.carPlans.length === 0) {
        dispatch(getCarPlans())
    }
    if (lookupsState?.carChinesNonChinese && lookupsState?.carChinesNonChinese.length === 0) {
        dispatch(getCarChinesNonChineseCarBrands())
    }
    if (lookupsState?.carModels && lookupsState?.carModels.length === 0) {
        dispatch(getCarModels())
    }
    if (lookupsState?.insuranceProductTypes && lookupsState?.insuranceProductTypes.length === 0) {
        dispatch(getInsuranceProductTypes())
    }
    if (lookupsState?.insuranceApplicationStatuses && lookupsState?.insuranceApplicationStatuses.length === 0) {
        dispatch(getInsuranceApplicationStatus())
    }
    if (lookupsState?.carModelYears && lookupsState?.carModelYears.length === 0) {
        dispatch(getCarModelsYears())
    }
    // if (lookupsState?.labels && lookupsState?.labels.length === 0) {
    //     dispatch(getLabels())
    // }
    // if (lookupsState?.agents && lookupsState?.agents.length === 0) {
    //     dispatch(getAgents())
    // }
    if (lookupsState?.medicalPlans && lookupsState?.medicalPlans.length === 0) {
        dispatch(getMedicalPlans())
    }
    if (lookupsState?.salesPersons && lookupsState?.salesPersons.length === 0) {
        dispatch(getSalePersons())
    }
    if (lookupsState?.leadCriteriaStatuses && lookupsState?.leadCriteriaStatuses.length === 0) {
        dispatch(getAllLeadCriteriaStatuses())
    }
    if (lookupsState?.rejectionReasons && lookupsState?.rejectionReasons.length === 0) {
        dispatch(getAllRejectionReasons())
    }
    if (lookupsState?.otherIncomeSource && lookupsState?.otherIncomeSource.length === 0) {
        dispatch(getOtherIncomeSource())
    }
    if (lookupsState?.otherIncomeSource && lookupsState?.otherIncomeSource.length === 0) {
        dispatch(getCompaniesProductTypes())
    }
    if (lookupsState?.applicationTypes && lookupsState?.applicationTypes.length === 0) {
        dispatch(getInsuranceApplicationTypes())
    }
}
