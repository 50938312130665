import { LOADING } from "src/redux/Loading/action"
import { loadingAction } from "src/redux/Loading/types"

export type Loading = {
    loading: boolean
}

const initialState: Loading = {
    loading: false,
}

const loadingReducer = (state: Loading = initialState, action: loadingAction): Loading => {
    let actionType
    switch (action.type) {
        case LOADING: {
            actionType = action as loadingAction
            return { ...state, loading: actionType?.payload?.loading }
        }
        default:
            return state
    }
}

export default loadingReducer
