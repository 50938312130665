import { Workbook } from "exceljs"
import RegexEnum from "src/enum/Regex.enum"

export const formatLookupsToReactSelectInput = (backendLookups: any[]) =>
    backendLookups?.map((lookup) => ({
        value: lookup?.id || lookup?.value,
        label: lookup?.label || lookup?.name || lookup?.nameEn || lookup.shortName || lookup.shortNameEn || lookup.fullName || "error: check-format",
        key: lookup?.id,
        category: "All",
    }))

export const formatLookupsGrouping = (backendLookups: { id: any; value: any; label: any; name: any; nameEn: any; shortName: any; shortNameEn: any; fullName: any; category: any }[]) =>
    backendLookups?.map((lookup) => ({
        value: lookup?.id || lookup?.value,
        label: lookup?.label || lookup?.name || lookup?.nameEn || lookup.shortName || lookup.shortNameEn || lookup.fullName || "error: check-format",
        key: lookup?.id,
        category: lookup?.category,
    }))

export const formatDateToReactSelectInputEdit = (lookups: any[], values: any[]) => {
    const allSelected: { key: any; value: any; label: any }[] = []
    values?.map((value) => {
        lookups?.map((lookup) => {
            if (value.id === lookup?.id) {
                const option = {
                    key: lookup?.id,
                    value: lookup?.id,
                    label: lookup?.name ?? lookup?.nameEn,
                }
                allSelected.push(option)
            }
            return lookup
        })
        return value
    })
    return allSelected
}

export const formatDateToReactSelectInputEditCreatable = (values: any[]) => {
    const allSelected: { key: any; value: any; label: any }[] = []
    values.map((value: any) => {
        const option = { key: value, value, label: value }
        allSelected.push(option)
        return value
    })
    return allSelected
}

export const urlParamsGenerator = (jsonParams: any) => {
    Object.keys(jsonParams).forEach((k) => {
        jsonParams[k] === undefined && delete jsonParams[k]
        ;(jsonParams[k] === undefined || jsonParams[k] === null || (typeof jsonParams[k] === "number" && Number.isNaN(jsonParams[k]))) && jsonParams[k] !== undefined && delete jsonParams[k]
    })
    return new URLSearchParams(jsonParams).toString()
}

export const numberFormat = (value = 0) =>
    new Intl.NumberFormat("en-EG", {
        style: "currency",
        currency: "EGP",
    })
        .format(value)
        .replaceAll("EGP", "")

export const dateFormatter = (cell: any) => {
    if (cell) {
        const date = new Date(cell)
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}:${(date.getMinutes() < 10 ? "0" : "") + date.getMinutes()}`
    }
    return cell
}

export const getCurrentDate = (cell: any) => {
    if (cell) {
        const date = new Date(cell)
        return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}}`
    }
    return cell
}

export const isEligible = (eligible: boolean) => (eligible ? "Eligible" : "Not-Eligible")

export const YesOrNo = (data: boolean) => (data ? "Yes" : "No")

export const ShortName = (data: any) => data || "-"

export const checkPdfExtension = (file: any) => file?.includes("data:application/pdf;base64,")

export const checkRotate = (deg: any) => {
    if (deg) {
        if (deg.includes("90")) {
            return 180
        }
        if (deg.includes("180")) {
            return 270
        }
        if (deg.includes("270")) {
            return 0
        }
    }
    return 90
}

export const download = (path: string, name: string) => {
    const a = document.createElement("a")
    a.href = path
    a.download = name
    a.target = "_blank"
    a.click()
}

export const str2bool = (value: string) => {
    if (value && typeof value === "string") {
        if (value.toLowerCase() === "true") return true
        if (value.toLowerCase() === "false") return false
    }
    return value
}

export const filterBody = (obj: any) => {
    Object.keys(obj).forEach((k) => {
        ;(typeof obj[k] === "object" && obj[k] !== null && obj[k].length === 0 && delete obj[k]) || (obj[k] && typeof obj[k] === "object" && filterBody(obj[k])) || ((obj[k] === "" || obj[k] === null) && obj[k] !== undefined && delete obj[k])
    })
    return obj
}

export const handleBackendErrors = (setShowErrors: (param: boolean) => void, error: { response: { data: { errors: string[] } } }, setBackendErrors: (param: any) => void) => {
    setShowErrors(true)
    window.scrollTo(0, 0)
    if (error?.response?.data) {
        const { errors } = error?.response?.data
        setBackendErrors(errors)
    }
}

export const saveProductClick = (btnRef: any) => {
    btnRef?.current?.click()
}

export const paginationOptions = (custom: any, sizePerPageList: any, totalSize: any, perPage: any, page: any) => ({
    custom,
    sizePerPageList,
    totalSize,
    perPage,
    page,
})

export const paginate = (value: { page: number; pageSize: any }, urlFilterParams: any, setUrlFilterParams: (arg0: any) => void) => {
    const queryString = {
        ...urlFilterParams,
        page: value?.page + 1 || 1,
        perPage: value?.pageSize || 25,
    }
    setUrlFilterParams(queryString)
}

export const phonesList = (value: any) => {
    if (value) {
        const body = value.split("\n")
        const documentType: boolean[] = []
        Array.from(body).forEach((val: any) => documentType.push(new RegExp(RegexEnum.Phone).test(val)))
        return !documentType.includes(false)
    }
    return value === null
}

export const deviceTokensList = (value: any) => {
    if (value) {
        const body = value.split("\n")
        const documentType: boolean[] = []
        Array.from(body).forEach((val: any) => documentType.push(new RegExp(RegexEnum.DeviceToken).test(val)))
        return !documentType.includes(false)
    }
    return value === null
}

export const exportExcelFile = (workbook: Workbook, fileName: string): void => {
    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement("a")
        anchor.href = url
        anchor.download = fileName
        anchor.click()
        window.URL.revokeObjectURL(url)
    })
}

export const RenewalCarInsurance = (carValue: number, rate: number): number => Number(Math.round(carValue * (rate / 100)).toFixed(2))

export const RenewalHomeInsurance = (buildingAmount: number, buildingRate: number, finishingAmount: number, finishingRate: number, furnitureAmount: number, furnitureRate: number): number => Number(Math.round(buildingAmount * (buildingRate / 100) + finishingAmount * (finishingRate / 100) + furnitureAmount * (furnitureRate / 100)).toFixed(2))

export const RenewalPersonalAccident = (coverageAmount: number, rate: number): number => Number(Math.round(coverageAmount * (rate / 100)).toFixed(2))

export const RenewalFireAndTheftInsurance = (buildingAmount: number, buildingRate: number, assetsAmount: number, assetsRate: number, otherAmount: number, otherRate: number): number => Number(Math.round(buildingAmount * (buildingRate / 100) + assetsAmount * (assetsRate / 100) + otherAmount * (otherRate / 100)).toFixed(2))
