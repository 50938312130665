import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { LookupAction, NormalLookupType } from "src/redux/Lookups/types"
import {
    ADMIN_APPLICATION_STATUSES,
    AGENTS,
    AGES,
    AREAS,
    BANKS,
    BONUS_TYPES,
    CAR_BRANDS,
    CAR_CHINES_NON_CHINES,
    CAR_LOAN_DOCUMENTS,
    CAR_MODEL_YEARS,
    CAR_MODELS,
    CAR_PLANS,
    CAR_TYPES,
    CARD_PRODUCT_TYPES,
    CARD_RATE_TYPES,
    CITIES,
    CITIES_AREAS,
    COMPANIES,
    COMPANY_SECTORS,
    CURRENCIES,
    DEPOSIT_RATE_TYPES,
    DOCUMENT_VALIDATES,
    EMPLOYEE_TYPES,
    EMPLOYEE_TYPES_HOME_LOAN,
    GENDER,
    HOME_LOAN_DOCUMENTS,
    INDUSTRIES,
    INSURANCE_APPLICATION_STATUS,
    INSURANCE_PRODUCT_TYPES,
    LABELS,
    LEADS_CRITERIA_STATUSES,
    OCCUPATIONS,
    PAYMENT_FREQUENCIES,
    PERSONAL_LOAN_DOCUMENTS,
    PRODUCT_NAMES,
    PRODUCT_TYPES,
    PROVIDER_TYPES,
    APPLICATION_REJECTION_REASONS,
    OTHER_INCOME_SOURCE,
    COMPANIES_PRODUCT_TYPES,
    REMOVE_ADMIN_APPLICATION_STATUSES,
    REMOVE_AGENTS,
    REMOVE_AGES,
    REMOVE_ALL_LOOKUPS,
    REMOVE_AREAS,
    REMOVE_BANKS,
    REMOVE_BONUS_TYPES,
    REMOVE_CAR_BRANDS,
    REMOVE_CAR_CHINES_NON_CHINES,
    REMOVE_CAR_LOAN_DOCUMENTS,
    REMOVE_CAR_MODEL_YEARS,
    REMOVE_CAR_MODELS,
    REMOVE_CAR_PLANS,
    REMOVE_CAR_TYPES,
    REMOVE_CARD_PRODUCT_TYPES,
    REMOVE_CARD_RATE_TYPES,
    REMOVE_CITIES,
    REMOVE_CITIES_AREAS,
    REMOVE_COMPANIES,
    REMOVE_COMPANY_SECTORS,
    REMOVE_CURRENCIES,
    REMOVE_DEPOSIT_RATE_TYPES,
    REMOVE_DOCUMENT_VALIDATES,
    REMOVE_EMPLOYEE_TYPES,
    REMOVE_EMPLOYEE_TYPES_HOME_LOAN,
    REMOVE_GENDER,
    REMOVE_HOME_LOAN_DOCUMENTS,
    REMOVE_INDUSTRIES,
    REMOVE_INSURANCE_APPLICATION_STATUS,
    REMOVE_INSURANCE_PRODUCT_TYPES,
    REMOVE_LABELS,
    REMOVE_LEADS_CRITERIA_STATUSES,
    REMOVE_OCCUPATIONS,
    REMOVE_PAYMENT_FREQUENCIES,
    REMOVE_PERSONAL_LOAN_DOCUMENTS,
    REMOVE_PRODUCT_NAMES,
    REMOVE_PRODUCT_TYPES,
    REMOVE_PROVIDER_TYPES,
    REMOVE_REWARD_TYPES,
    REMOVE_ROLES,
    REMOVE_SALARY_TRANSFER_STATUSES,
    REWARD_TYPES,
    ROLES,
    SALARY_TRANSFER_STATUSES,
    MEDICAL_PLANS,
    REMOVE_MEDICAL_PLANS,
    SALES_PERSONS,
    REMOVE_SALES_PERSONS,
    REMOVE_OTHER_INCOME_SOURCE,
    REMOVE_COMPANIES_PRODUCT_TYPES,
    REMOVE_APPLICATION_REJECTION_REASONS,
    APPLICATION_TYPES,
    REMOVE_APPLICATION_TYPES,
} from "src/redux/Lookups/action"

export type Lookups = {
    roles: NormalLookupType[]
    banks: NormalLookupType[]
    productNames: NormalLookupType[]
    productTypes: NormalLookupType[]
    employeeTypes: NormalLookupType[]
    employeeTypesHomeLoan: NormalLookupType[]
    salaryTransferStatuses: NormalLookupType[]
    industries: NormalLookupType[]
    companySectors: NormalLookupType[]
    cities: NormalLookupType[]
    areas: NormalLookupType[]
    citiesAreas: NormalLookupType[]
    documentValidates: NormalLookupType[]
    personalLoanDocuments: NormalLookupType[]
    homeLoanDocuments: NormalLookupType[]
    carLoanDocuments: NormalLookupType[]
    carTypes: NormalLookupType[]
    cardRateTypes: NormalLookupType[]
    depositRateTypes: NormalLookupType[]
    currencies: NormalLookupType[]
    paymentFrequencies: NormalLookupType[]
    cardProductTypes: NormalLookupType[]
    providerTypes: NormalLookupType[]
    gender: NormalLookupType[]
    bonusTypes: NormalLookupType[]
    adminApplicationStatuses: NormalLookupType[]
    rewardTypes: NormalLookupType[]
    occupations: NormalLookupType[]
    companies: NormalLookupType[]
    ages: NormalLookupType[]
    carBrands: NormalLookupType[]
    carPlans: NormalLookupType[]
    carModels: NormalLookupType[]
    carChinesNonChinese: NormalLookupType[]
    insuranceProductTypes: NormalLookupType[]
    insuranceApplicationStatuses: NormalLookupType[]
    carModelYears: NormalLookupType[]
    labels: NormalLookupType[]
    agents: NormalLookupType[]
    leadCriteriaStatuses: NormalLookupType[]
    medicalPlans: NormalLookupType[]
    salesPersons: NormalLookupType[]
    rejectionReasons: NormalLookupType[]
    otherIncomeSource: NormalLookupType[]
    companiesProductTypes: NormalLookupType[]
    applicationTypes: NormalLookupType[]
}

const initialLookupsState: Lookups = {
    roles: [],
    banks: [],
    productNames: [],
    productTypes: [],
    employeeTypes: [],
    employeeTypesHomeLoan: [],
    salaryTransferStatuses: [],
    industries: [],
    companySectors: [],
    cities: [],
    areas: [],
    citiesAreas: [],
    documentValidates: [],
    personalLoanDocuments: [],
    homeLoanDocuments: [],
    carLoanDocuments: [],
    carTypes: [],
    cardRateTypes: [],
    depositRateTypes: [],
    currencies: [],
    paymentFrequencies: [],
    cardProductTypes: [],
    providerTypes: [],
    gender: [],
    adminApplicationStatuses: [],
    rewardTypes: [],
    bonusTypes: [],
    occupations: [],
    companies: [],
    ages: [],
    carBrands: [],
    carPlans: [],
    carModels: [],
    carChinesNonChinese: [],
    insuranceProductTypes: [],
    insuranceApplicationStatuses: [],
    carModelYears: [],
    labels: [],
    agents: [],
    leadCriteriaStatuses: [],
    medicalPlans: [],
    salesPersons: [],
    rejectionReasons: [],
    otherIncomeSource: [],
    companiesProductTypes: [],
    applicationTypes: [],
}

const lookupsReducer = (state: Lookups = initialLookupsState, action: LookupAction) => {
    switch (action.type) {
        case ROLES: {
            const { payload } = action
            return { ...state, roles: payload }
        }
        case BANKS: {
            const { payload } = action
            return { ...state, banks: payload }
        }
        case PRODUCT_NAMES: {
            const { payload } = action
            return { ...state, productNames: payload }
        }
        case PRODUCT_TYPES: {
            const { payload } = action
            return { ...state, productTypes: payload }
        }
        case EMPLOYEE_TYPES: {
            const { payload } = action
            return { ...state, employeeTypes: payload }
        }
        case EMPLOYEE_TYPES_HOME_LOAN: {
            const { payload } = action
            return { ...state, employeeTypesHomeLoan: payload }
        }
        case SALARY_TRANSFER_STATUSES: {
            const { payload } = action
            return { ...state, salaryTransferStatuses: payload }
        }
        case INDUSTRIES: {
            const { payload } = action
            return { ...state, industries: payload }
        }
        case COMPANY_SECTORS: {
            const { payload } = action
            return { ...state, companySectors: payload }
        }
        case CITIES: {
            const { payload } = action
            return { ...state, cities: payload }
        }
        case AREAS: {
            const { payload } = action
            return { ...state, areas: payload }
        }
        case CITIES_AREAS: {
            const { payload } = action
            return { ...state, citiesAreas: payload }
        }
        case DOCUMENT_VALIDATES: {
            const { payload } = action
            return { ...state, documentValidates: payload }
        }
        case PERSONAL_LOAN_DOCUMENTS: {
            const { payload } = action
            return { ...state, personalLoanDocuments: payload }
        }
        case HOME_LOAN_DOCUMENTS: {
            const { payload } = action
            return { ...state, homeLoanDocuments: payload }
        }
        case CAR_LOAN_DOCUMENTS: {
            const { payload } = action
            return { ...state, carLoanDocuments: payload }
        }
        case CAR_TYPES: {
            const { payload } = action
            return { ...state, carTypes: payload }
        }
        case CARD_RATE_TYPES: {
            const { payload } = action
            return { ...state, cardRateTypes: payload }
        }
        case DEPOSIT_RATE_TYPES: {
            const { payload } = action
            return { ...state, depositRateTypes: payload }
        }
        case CURRENCIES: {
            const { payload } = action
            return { ...state, currencies: payload }
        }
        case PAYMENT_FREQUENCIES: {
            const { payload } = action
            return { ...state, paymentFrequencies: payload }
        }
        case CARD_PRODUCT_TYPES: {
            const { payload } = action
            return { ...state, cardProductTypes: payload }
        }
        case PROVIDER_TYPES: {
            const { payload } = action
            return { ...state, providerTypes: payload }
        }
        case GENDER: {
            const { payload } = action
            return { ...state, gender: payload }
        }
        case ADMIN_APPLICATION_STATUSES: {
            const { payload } = action
            return { ...state, adminApplicationStatuses: payload }
        }
        case REWARD_TYPES: {
            const { payload } = action
            return { ...state, rewardTypes: payload }
        }
        case BONUS_TYPES: {
            const { payload } = action
            return { ...state, bonusTypes: payload }
        }
        case OCCUPATIONS: {
            const { payload } = action
            return { ...state, occupations: payload }
        }
        case COMPANIES: {
            const { payload } = action
            return { ...state, companies: payload }
        }
        case AGES: {
            const { payload } = action
            return { ...state, ages: payload }
        }
        case CAR_BRANDS: {
            const { payload } = action
            return { ...state, carBrands: payload }
        }
        case CAR_PLANS: {
            const { payload } = action
            return { ...state, carPlans: payload }
        }
        case CAR_MODELS: {
            const { payload } = action
            return { ...state, carModels: payload }
        }
        case CAR_CHINES_NON_CHINES: {
            const { payload } = action
            return { ...state, carChinesNonChinese: payload }
        }
        case INSURANCE_PRODUCT_TYPES: {
            const { payload } = action
            return { ...state, insuranceProductTypes: payload }
        }
        case INSURANCE_APPLICATION_STATUS: {
            const { payload } = action
            return { ...state, insuranceApplicationStatuses: payload }
        }
        case CAR_MODEL_YEARS: {
            const { payload } = action
            return { ...state, carModelYears: payload }
        }
        case LABELS: {
            const { payload } = action
            return { ...state, labels: payload }
        }
        case AGENTS: {
            const { payload } = action
            return { ...state, agents: payload }
        }
        case LEADS_CRITERIA_STATUSES: {
            const { payload } = action
            return { ...state, leadCriteriaStatuses: payload }
        }
        case MEDICAL_PLANS: {
            const { payload } = action
            return { ...state, medicalPlans: payload }
        }
        case SALES_PERSONS: {
            const { payload } = action
            return { ...state, salesPersons: payload }
        }
        case APPLICATION_REJECTION_REASONS: {
            const { payload } = action
            return { ...state, rejectionReasons: payload }
        }
        case OTHER_INCOME_SOURCE: {
            const { payload } = action
            return { ...state, otherIncomeSource: payload }
        }
        case COMPANIES_PRODUCT_TYPES: {
            const { payload } = action
            return { ...state, companiesProductTypes: payload }
        }
        case APPLICATION_TYPES: {
            const { payload } = action
            return { ...state, applicationTypes: payload }
        }
        case REMOVE_ROLES: {
            return { ...state, roles: [] }
        }
        case REMOVE_BANKS: {
            return { ...state, banks: [] }
        }
        case REMOVE_PRODUCT_NAMES: {
            return { ...state, productNames: [] }
        }
        case REMOVE_PRODUCT_TYPES: {
            return { ...state, productTypes: [] }
        }
        case REMOVE_EMPLOYEE_TYPES: {
            return { ...state, employeeTypes: [] }
        }
        case REMOVE_EMPLOYEE_TYPES_HOME_LOAN: {
            return { ...state, employeeTypesHomeLoan: [] }
        }
        case REMOVE_SALARY_TRANSFER_STATUSES: {
            return { ...state, salaryTransferStatuses: [] }
        }
        case REMOVE_INDUSTRIES: {
            return { ...state, industries: [] }
        }
        case REMOVE_COMPANY_SECTORS: {
            return { ...state, companySectors: [] }
        }
        case REMOVE_CITIES: {
            return { ...state, cities: [] }
        }
        case REMOVE_AREAS: {
            return { ...state, areas: [] }
        }
        case REMOVE_CITIES_AREAS: {
            return { ...state, citiesAreas: [] }
        }
        case REMOVE_DOCUMENT_VALIDATES: {
            return { ...state, documentValidates: [] }
        }
        case REMOVE_PERSONAL_LOAN_DOCUMENTS: {
            return { ...state, personalLoanDocuments: [] }
        }
        case REMOVE_HOME_LOAN_DOCUMENTS: {
            return { ...state, homeLoanDocuments: [] }
        }
        case REMOVE_CAR_LOAN_DOCUMENTS: {
            return { ...state, carLoanDocuments: [] }
        }
        case REMOVE_CAR_TYPES: {
            return { ...state, carTypes: [] }
        }
        case REMOVE_CARD_RATE_TYPES: {
            return { ...state, cardRateTypes: [] }
        }
        case REMOVE_DEPOSIT_RATE_TYPES: {
            return { ...state, depositRateTypes: [] }
        }
        case REMOVE_CURRENCIES: {
            return { ...state, currencies: [] }
        }
        case REMOVE_PAYMENT_FREQUENCIES: {
            return { ...state, paymentFrequencies: [] }
        }
        case REMOVE_CARD_PRODUCT_TYPES: {
            return { ...state, cardProductTypes: [] }
        }
        case REMOVE_PROVIDER_TYPES: {
            return { ...state, providerTypes: [] }
        }
        case REMOVE_GENDER: {
            return { ...state, gender: [] }
        }
        case REMOVE_ADMIN_APPLICATION_STATUSES: {
            return { ...state, adminApplicationStatuses: [] }
        }
        case REMOVE_REWARD_TYPES: {
            return { ...state, rewardTypes: [] }
        }
        case REMOVE_BONUS_TYPES: {
            return { ...state, bonusTypes: [] }
        }
        case REMOVE_OCCUPATIONS: {
            return { ...state, occupations: [] }
        }
        case REMOVE_COMPANIES: {
            return { ...state, companies: [] }
        }
        case REMOVE_AGES: {
            return { ...state, ages: [] }
        }
        case REMOVE_CAR_BRANDS: {
            return { ...state, carBrands: [] }
        }
        case REMOVE_CAR_PLANS: {
            return { ...state, carPlans: [] }
        }
        case REMOVE_CAR_MODELS: {
            return { ...state, carModels: [] }
        }
        case REMOVE_CAR_CHINES_NON_CHINES: {
            return { ...state, carChinesNonChinese: [] }
        }
        case REMOVE_INSURANCE_PRODUCT_TYPES: {
            return { ...state, insuranceProductTypes: [] }
        }
        case REMOVE_INSURANCE_APPLICATION_STATUS: {
            return { ...state, insuranceApplicationStatuses: [] }
        }
        case REMOVE_CAR_MODEL_YEARS: {
            return { ...state, carModelYears: [] }
        }
        case REMOVE_LABELS: {
            return { ...state, labels: [] }
        }
        case REMOVE_AGENTS: {
            return { ...state, agents: [] }
        }
        case REMOVE_LEADS_CRITERIA_STATUSES: {
            return { ...state, leadCriteriaStatuses: [] }
        }
        case REMOVE_MEDICAL_PLANS: {
            return { ...state, medicalPlans: [] }
        }
        case REMOVE_SALES_PERSONS: {
            return { ...state, salesPersons: [] }
        }
        case REMOVE_APPLICATION_REJECTION_REASONS: {
            return { ...state, rejectionReasons: [] }
        }
        case REMOVE_OTHER_INCOME_SOURCE: {
            return { ...state, otherIncomeSource: [] }
        }
        case REMOVE_COMPANIES_PRODUCT_TYPES: {
            return { ...state, companiesProductTypes: [] }
        }
        case REMOVE_APPLICATION_TYPES: {
            return { ...state, applicationTypes: [] }
        }
        case REMOVE_ALL_LOOKUPS: {
            return initialLookupsState
        }
        default:
            return state
    }
}

export default persistReducer(
    {
        storage,
        key: "lookups",
        whitelist: [
            "roles",
            "banks",
            "productNames",
            "productTypes",
            "employeeTypes",
            "employeeTypesHomeLoan",
            "salaryTransferStatuses",
            "industries",
            "companySectors",
            "cities",
            "areas",
            "citiesAreas",
            "documentValidates",
            "personalLoanDocuments",
            "homeLoanDocuments",
            "carLoanDocuments",
            "carTypes",
            "cardRateTypes",
            "depositRateTypes",
            "currencies",
            "paymentFrequencies",
            "cardProductTypes",
            "providerTypes",
            "gender",
            "adminApplicationStatuses",
            "rewardTypes",
            "bonusTypes",
            "occupations",
            "companies",
            "ages",
            "carBrands",
            "carPlans",
            "carModels",
            "carChinesNonChinese",
            "insuranceProductTypes",
            "insuranceApplicationStatuses",
            "carModelYears",
            "labels",
            "agents",
            "leadCriteriaStatuses",
            "medicalPlans",
            "salesPersons",
            "rejectionReasons",
            "otherIncomeSource",
            "companiesProductTypes",
            "applicationTypes",
        ],
    },
    lookupsReducer
)
