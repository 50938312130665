import { createContext, useContext, useEffect, useState } from "react"

const FaydetySplashScreenContext = createContext({} as any)

export default function FaydetySplashScreenProvider(props: { children: any }) {
    const { children } = props
    const [count, setCount] = useState<0>(0)
    const visible = count > 0

    useEffect(() => {
        const splashScreen = document.getElementById("splash-screen")

        if (splashScreen && visible) {
            splashScreen.classList.remove("hidden")

            return () => {
                splashScreen.classList.add("hidden")
            }
        }
        let timeout
        if (splashScreen && !visible) {
            timeout = setTimeout(() => {
                splashScreen.classList.add("hidden")
            }, 3000)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [visible])

    return <FaydetySplashScreenContext.Provider value={setCount}>{children}</FaydetySplashScreenContext.Provider>
}

export function LayoutSplashScreen({ visible = true }) {
    const setCount = useContext(FaydetySplashScreenContext)
    useEffect(() => {
        if (!visible) {
            return
        }
        setCount((prev: number) => prev + 1)
    }, [setCount, visible])

    return null
}
