import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import routes, { renderRoutes } from "src/navigation/index"
import I18nProvider from "src/language/I18nProvider"
import { AuthProvider } from "src/contexts/AuthContext"
import { LayoutSplashScreen } from "src/layouts/_core/FaydetySplashScreen"
import MaterialThemeProvider from "src/theme/MaterialThemeProvider"
import store, { persistor } from "src/redux/store"

export default function App() {
    const { PUBLIC_URL } = process.env
    return (
        <MaterialThemeProvider>
            <I18nProvider>
                <Provider store={store}>
                    <AuthProvider>
                        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
                            {renderRoutes(routes, PUBLIC_URL)}
                        </PersistGate>
                    </AuthProvider>
                </Provider>
            </I18nProvider>
        </MaterialThemeProvider>
    )
}
