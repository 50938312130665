import axios from "axios"
import store from "src/redux/store"
import { Navigate } from "react-router-dom"
import { HTTP_STATUS_SERVER_ERROR_500, HTTP_STATUS_SERVER_ERROR_501, HTTP_STATUS_SERVER_ERROR_502, HTTP_STATUS_SERVER_ERROR_503, HTTP_STATUS_UNAUTHORIZED } from "src/helpers"
import { AUTH_LOGOUT } from "src/redux/Auth/action"
import { REMOVE_ALL_LOOKUPS, removeLookupAction } from "src/redux/Lookups/action"

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_LINK,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        accept: "application/json",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    },
})

export const axiosInstanceNotification = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_CORES}${process.env.REACT_APP_NOTIFICATION}`,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    },
})

axiosInstance.interceptors.request.use(
    (config) => {
        const state = store.getState()
        config.headers.Authorization = `Bearer ${state?.auth?.authToken}`
        config.headers.lang = state?.auth?.lang || "en"
        config.headers.AcceptLanguage = "en,ar"
        return config
    },
    (error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
    (response) => response.data,
    (error) => {
        if (error?.response?.status === 401) {
            store.dispatch({ type: AUTH_LOGOUT })
            store.dispatch(removeLookupAction({ type: REMOVE_ALL_LOOKUPS }))
            return <Navigate to="/auth/login" />
        }
        if (error?.response?.status === HTTP_STATUS_UNAUTHORIZED) {
            return <Navigate to="/403" />
        }
        if (error?.response?.status === HTTP_STATUS_SERVER_ERROR_500 || error?.response?.status === HTTP_STATUS_SERVER_ERROR_501 || error?.response?.status === HTTP_STATUS_SERVER_ERROR_502 || error?.response?.status === HTTP_STATUS_SERVER_ERROR_503) {
            return Promise.reject(error)
            // return <Navigate to="/500" />
        }
        return Promise.reject(error)
    }
)

export default axiosInstance
