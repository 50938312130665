import { createContext, useEffect, useState } from "react"
import { shallowEqual, useSelector } from "react-redux"
import LoadingScreen from "src/screens/LoadingScreen"
import { TypeIsAuthenticated } from "src/types"

export const AuthContext = createContext<TypeIsAuthenticated>({ isAuthenticated: false })

export const AuthProvider = (props: { children: any }) => {
    const { children } = props
    const [loading, setLoading] = useState(false)
    const [isAuthenticated, setAuth] = useState(false)
    const { isAuthorized } = useSelector(
        (state: any) => ({
            isAuthorized: !!state?.auth?.user,
            roleId: state?.auth?.auth?.roleId,
        }),
        shallowEqual
    )

    useEffect(() => {
        setLoading(true)
        if (isAuthorized) {
            setAuth(true)
            setLoading(false)
        } else {
            setAuth(false)
            setLoading(false)
        }
    }, [isAuthorized])

    const value = {
        isAuthenticated,
    }

    return <AuthContext.Provider value={value}>{loading ? <LoadingScreen /> : children}</AuthContext.Provider>
}
