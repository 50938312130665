import { StrictMode } from "react"
import ReactDOM from "react-dom"
import "src/styles/index.css"
import FaydetySplashScreenProvider from "src/layouts/_core/FaydetySplashScreen"
import FaydetyI18nProvider from "src/language/I18nProvider"
import App from "src/App"
import reportWebVitals from "src/reportWebVitals"

ReactDOM.render(
    <StrictMode>
        <FaydetyI18nProvider>
            <FaydetySplashScreenProvider>
                <App />
            </FaydetySplashScreenProvider>
        </FaydetyI18nProvider>
    </StrictMode>,
    document.getElementById("root")
)

// @ts-ignore
reportWebVitals()
