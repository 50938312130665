enum RegexEnum {
    NATIONAL_NUMBER = "^(2|3)[0-9][0-9][0-1][0-9][0-3][0-9](01|02|03|04|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)[0-9]{5}$",
    PASSWORD_REGEX = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$",
    ARABIC_STRING_REGEX = '^[\u0621-\u064A  \\d@#$%^&*()-_=+!.?><,:;\\/"،؟\\{}]+$',
    ENGLISH_STRING_REGEX = '^[A-Za-z \\\\d@#$%^&*()-_=+!.?><,:;\\\\/"،؟\\\\{}]+$',
    LINK_REGEX = "^[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\-\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\-\\+.~#?&//=]*)$",
    Phone = "^01(0|1|2|5)[0-9]{8}$",
    DeviceToken = "^[A-Za-z0-9\\[\\]\\_\\-]{41}$",
}

export default RegexEnum
