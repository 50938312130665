import { IntlProvider } from "react-intl"

import enMessages from "src/language/messages/en.json"
import arMessages from "src/language/messages/ar.json"

const allMessages = {
    en: enMessages,
    ar: arMessages,
}

export default function I18nProvider({ children }) {
    const messages = allMessages["en"]
    return (
        <IntlProvider locale="en" messages={messages}>
            {children}
        </IntlProvider>
    )
}
