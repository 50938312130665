import { loadingAction } from "src/redux/Loading/types"
import { Loading } from "src/redux/Loading/reducer"

export const LOADING = "LOADING"

const loadingToggleAction = ({ loading }: Loading): loadingAction => ({
    type: LOADING,
    payload: { loading },
})

export const setLoading =
    ({ loading }: Loading) =>
    async (dispatch: any) =>
        dispatch(loadingToggleAction({ loading }))
