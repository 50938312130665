import { createStore, applyMiddleware, combineReducers } from "redux"
import thunkMiddleware from "redux-thunk"
import { persistStore } from "redux-persist"
import { logger } from "redux-logger"
import authReducer, { Auth } from "src/redux/Auth/reducer"
import lookupsReducer, { Lookups } from "src/redux/Lookups/reducer"
import navbarReducer, { Navbar } from "src/redux/Navbar/reducer"
import loadingReducer, { Loading } from "src/redux/Loading/reducer"
import { dispatchAllLookups } from "src/redux/Lookups/action"

export interface Store {
    auth: Auth
    lookups: Lookups
    navbar: Navbar
    loading: Loading
}

const middleware = process.env.NODE_ENV !== "production" ? applyMiddleware(thunkMiddleware, logger) : applyMiddleware(thunkMiddleware)

const store = createStore(
    combineReducers({
        auth: authReducer,
        lookups: lookupsReducer,
        navbar: navbarReducer,
        loading: loadingReducer,
    }),
    undefined,
    middleware
)

export const persistor = persistStore(store, null, () => store.dispatch(dispatchAllLookups))

export default store
