import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { AUTH_LOGIN, AUTH_LOGOUT, SET_AUTH_LANG } from "src/redux/Auth/action"
import { UserData, UserAuthAction, UserAuthLogoutAction, UserUpdateLangAction } from "src/redux/Auth/types"

export type Auth = {
    user?: UserData | undefined
    authToken?: string | undefined
    lang?: string | undefined
}

const initialState: Auth = {
    user: undefined,
    authToken: undefined,
    lang: "en",
}

const authReducer = (state: Auth = initialState, action: UserAuthAction | UserAuthLogoutAction | UserUpdateLangAction): Auth => {
    let actionType
    switch (action.type) {
        case AUTH_LOGIN: {
            actionType = action as UserAuthAction
            return { ...state, authToken: actionType?.payload?.authToken, user: actionType?.payload?.user }
        }
        case SET_AUTH_LANG: {
            actionType = action as UserUpdateLangAction
            return { ...state, lang: actionType?.payload?.lang }
        }
        case AUTH_LOGOUT: {
            return initialState
        }
        default:
            return state
    }
}

export default persistReducer({ storage, key: "auth", whitelist: ["authToken", "user", "lang"] }, authReducer)
