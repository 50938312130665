import { FC, memo } from "react"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        backgroundColor: theme.palette.primary.light,
    },
}))

const LoadingScreen: FC = () => {
    const classes = useStyles()

    return (
        <Grid container justifyContent="center" alignItems="center" className={classes.root}>
            <CircularProgress color="primary" size={120} />
        </Grid>
    )
}

export default memo(LoadingScreen)
