export const HTTP_STATUS_UNAUTHORIZED = 403
export const HTTP_STATUS_NOT_FOUND_ERROR = 404
export const HTTP_STATUS_SERVER_ERROR_500 = 500
export const HTTP_STATUS_SERVER_ERROR_501 = 501
export const HTTP_STATUS_SERVER_ERROR_502 = 502
export const HTTP_STATUS_SERVER_ERROR_503 = 503
export const BANK_NEED_ACTION = "[9, 10, 11, 12, 13, 14, 15]"
export const ADMIN_STATUS = [
    { id: 1, name: "In Complete" },
    {
        id: 2,
        name: "Under Reviewer Review",
    },
    { id: 3, name: "Under Supervisor Review" },
    { id: 4, name: "Faydety Need Action" },
    {
        id: 5,
        name: "Under Bank Review",
    },
    { id: 6, name: "Pre-Approved" },
    { id: 7, name: "Iscore / Investigation + More Docs" },
    {
        id: 8,
        name: "Iscore / Investigation + Medical Check",
    },
    { id: 9, name: "More Docs + Medical Check" },
    {
        id: 10,
        name: "Iscore / Investigation + More Docs + Medical Check",
    },
    { id: 11, name: "Medical Check" },
    { id: 12, name: "Iscore / Investigation" },
    {
        id: 13,
        name: "More Docs",
    },
    { id: 14, name: "Rejected" },
    { id: 15, name: "Completed" },
    { id: 16, name: "Expired" },
    {
        id: 17,
        name: "Canceled Under Faydety Review",
    },
    { id: 18, name: "Canceled Under Bank Review" },
    { id: 19, name: "Canceled" },
    {
        id: 20,
        name: "followup by bank",
    },
    { id: 21, name: "Rejected Under Faydety" },
    { id: 22, name: "Follow Up By Faydety" },
]
export const drawerWidth = 250
export const DEFAULT_PER_PAGE = 25
export const sizePerPageList = [25, 50, 75, 100]
export const InsuranceTypeName = ["Personal Accident", "Home Insurance", "Car Insurance"]

export const sourceOption = [
    { id: "website", name: "Website" },
    { id: "mobile", name: "Mobile" },
    { id: "quotation", name: "Quotation" },
    { id: "sales", name: "Sales" },
]
