import { Fragment, lazy, Suspense } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import LoadingScreen from "src/screens/LoadingScreen"
import { AuthGuard, GuestGuard } from "src/guard/index"
import AuthLayout from "src/layouts/AuthLayout/AuthLayout"
import DashboardLayout from "src/layouts/DashboardLayout"
import ErrorLayout from "src/layouts/ErrorLayout/ErrorLayout"
import RolesEnum from "src/enum/Roles.enum"

const routes: any = [
    //  =============== Dashbaord ===============
    {
        path: "/",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE, RolesEnum.MICRO_FINANCE_ROLE, RolesEnum.MARKETING_ROLE],
        component: lazy(() => import("src/views/Dashboard")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Contact Application ===============
    {
        path: "/contact-applications",
        role: [RolesEnum.SUPER_ADMIN_ROLE],
        component: lazy(() => import("src/views/ContactApplications/Applications")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Reports ===============
    {
        path: "/reports",
        role: [RolesEnum.SUPER_ADMIN_ROLE],
        component: lazy(() => import("src/views/Reports")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Notification ===============
    {
        path: "/notification/send-bulk",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.MARKETING_ROLE],
        component: lazy(() => import("src/views/Notification/Message")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Referrals ===============
    {
        path: "/referrals",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.MARKETING_ROLE],
        component: lazy(() => import("src/views/Referrals")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Leads ===============
    {
        path: "/leads",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.MARKETING_ROLE],
        component: lazy(() => import("src/views/Leads")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/leads/:type/:id",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.MARKETING_ROLE],
        component: lazy(() => import("src/views/Leads")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/lead/:id",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.MARKETING_ROLE],
        component: lazy(() => import("src/views/Leads/Show")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/leadCriteria/:type/:productTypeId/:id/:criteriaId",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.MARKETING_ROLE],
        component: lazy(() => import("src/views/Leads/Show/criteria-details")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== LeadStatistics ===============
    {
        path: "/leadStatistics",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.MARKETING_ROLE],
        component: lazy(() => import("src/views/LeadsStatistics/Show")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Wassenger ===============
    {
        path: "/wassenger/message",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Wassenger/Message")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/wassenger/labeling",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Wassenger/Labeling")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Admins ===============
    {
        path: "/admins/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Admins/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/admins",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Admins")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/admins/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Admins/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/admins/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Admins/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Admins ===============
    {
        path: "/rejection-reason/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Lookups/RejectionReasons/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/rejection-reason",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Lookups/RejectionReasons")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/rejection-reason/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Lookups/RejectionReasons/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/rejection-reason/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Lookups/RejectionReasons/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Product Types ===============
    {
        path: "/product-types/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE],
        component: lazy(() => import("src/views/Lookups/ProductTypes/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/product-types",
        role: [RolesEnum.SUPER_ADMIN_ROLE],
        component: lazy(() => import("src/views/Lookups/ProductTypes")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/product-types/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE],
        component: lazy(() => import("src/views/Lookups/ProductTypes/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/product-types/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE],
        component: lazy(() => import("src/views/Lookups/ProductTypes/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Documents ===============
    {
        path: "/Documents/Documents/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/Lookups/Document/Documents/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/Documents/Documents",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/Lookups/Document/Documents")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/Documents/Documents/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/Lookups/Document/Documents/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/Documents/Documents/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/Lookups/Document/Documents/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Documents Validity ===============
    {
        path: "/Documents/document-validity/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/Lookups/Document/DocumentValidity/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/Documents/document-validity",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/Lookups/Document/DocumentValidity")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/Documents/document-validity/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/Lookups/Document/DocumentValidity/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/Documents/document-validity/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/Lookups/Document/DocumentValidity/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Banks ===============
    {
        path: "/banks/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Banks/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/banks",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Banks")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/banks/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Banks/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/banks/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Banks/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Personal PersonalAccident ===============
    {
        path: "/products/personal-loan/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/PersonalLoan/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/personal-loan",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/PersonalLoan")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/personal-loan/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/PersonalLoan/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/personal-loan/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/PersonalLoan/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Car PersonalAccident ===============
    {
        path: "/products/car-loan/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/CarLoan/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/car-loan",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/CarLoan")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/car-loan/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/CarLoan/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/car-loan/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/CarLoan/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Home PersonalAccident ===============
    {
        path: "/products/home-loan/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/HomeLoan/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/home-loan",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/HomeLoan")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/home-loan/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/HomeLoan/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/home-loan/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/HomeLoan/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Deposits ===============
    {
        path: "/products/deposits/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/Deposits/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/deposits",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/Deposits")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/deposits/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/Deposits/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/deposits/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/Deposits/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Cards ===============
    {
        path: "/products/cards/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/Cards/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/cards",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/Cards")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/cards/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/Cards/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/products/cards/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/BankProducts/Cards/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Companies ===============
    {
        path: "/companies/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Lookups/Companies/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/companies",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Lookups/Companies")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/companies/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Lookups/Companies/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/Companies/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Lookups/Companies/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== medical-card-services-types ===============
    {
        path: "/medical-card-services-types/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE],
        component: lazy(() => import("src/views/Lookups/MedicalCardServicesType/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/medical-card-services-types",
        role: [RolesEnum.SUPER_ADMIN_ROLE],
        component: lazy(() => import("src/views/Lookups/MedicalCardServicesType")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/medical-card-services-types/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE],
        component: lazy(() => import("src/views/Lookups/MedicalCardServicesType/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/medical-card-services-types/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE],
        component: lazy(() => import("src/views/Lookups/MedicalCardServicesType/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Documents ===============
    {
        path: "/car-brands/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Lookups/CarBrands/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/car-brands",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Lookups/CarBrands")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/car-brands/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Lookups/CarBrands/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/car-brands/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Lookups/CarBrands/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/car-brands/car-model/:id",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/Lookups/CarBrands/CarPricing")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Plans ===============
    {
        path: "/plans/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE],
        component: lazy(() => import("src/views/Lookups/Plans/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/plans",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/Lookups/Plans")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/plans/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/Lookups/Plans/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/plans/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/Lookups/Plans/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Plans ===============
    {
        path: "/medical-plans/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE],
        component: lazy(() => import("src/views/Lookups/MedicalPlans/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/medical-plans",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/Lookups/MedicalPlans")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/medical-plans/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/Lookups/MedicalPlans/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/medical-plans/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.DATA_ENTRY_ROLE],
        component: lazy(() => import("src/views/Lookups/MedicalPlans/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== insurance/personal-accidents ===============
    {
        path: "/insurance/personal-accidents/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/PersonalAccidents/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/personal-accidents",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/PersonalAccidents")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/personal-accidents/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/PersonalAccidents/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/personal-accidents/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/PersonalAccidents/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== insurance/home-insurances ===============
    {
        path: "/insurance/home-insurances/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/HomeInsurance/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/home-insurances",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/HomeInsurance")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/home-insurances/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/HomeInsurance/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/home-insurances/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/HomeInsurance/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== insurance/Fire-Theft-insurances ===============
    {
        path: "/insurance/fire-theft-insurances/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/FireAndTheftInsurance/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/fire-theft-insurances",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/FireAndTheftInsurance")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/fire-theft-insurances/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/FireAndTheftInsurance/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/fire-theft-insurances/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/FireAndTheftInsurance/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== insurance/car-insurances ===============
    {
        path: "/insurance/car-insurances/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CarInsurance/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/car-insurances",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CarInsurance")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/car-insurances/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CarInsurance/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/car-insurances/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CarInsurance/Create")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/car-insurances/:carId/sub-car-insurance/:companyId",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CarInsurance/SubCarInsurance")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/car-insurances/:carId/sub-car-insurance/:companyId/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CarInsurance/SubCarInsurance/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/car-insurances/:carId/sub-car-insurance/:companyId/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CarInsurance/SubCarInsurance/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/car-insurances/:carId/sub-car-insurance/:companyId/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CarInsurance/SubCarInsurance/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/car-insurances/:carId/car-insurance-documents/:companyId/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CarInsurance/CarInsuranceDocuments/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== insurance/car-insurances ===============
    {
        path: "/insurance/medical-insurances/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/MedicalInsurance/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/medical-insurances",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/MedicalInsurance")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/medical-insurances/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/MedicalInsurance/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/medical-insurances/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/MedicalInsurance/Create")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/medical-insurances/:medicalId/sub-medical-insurance/:companyId",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/MedicalInsurance/SubMedicalInsurance")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/medical-insurances/:medicalId/sub-medical-insurance/:companyId/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/MedicalInsurance/SubMedicalInsurance/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/medical-insurances/:medicalId/sub-medical-insurance/:companyId/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/MedicalInsurance/SubMedicalInsurance/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/medical-insurances/:medicalId/sub-medical-insurance/:companyId/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/MedicalInsurance/SubMedicalInsurance/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/medical-insurances/:medicalId/medical-insurance-documents/:companyId/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/MedicalInsurance/MedicalInsuranceDocuments/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/medical-insurances/:medicalId/sub-bulk-medical-insurance/:companyId",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/MedicalInsurance/SubMedicalInsurance/BulkEdit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== insurance/car-insurances ===============
    {
        path: "/insurance/cancer-insurances/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CancerInsurance/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/cancer-insurances",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CancerInsurance")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/cancer-insurances/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CancerInsurance/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/cancer-insurances/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CancerInsurance/Create")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/cancer-insurances/:cancerId/sub-cancer-insurance/:companyId",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CancerInsurance/SubCancerInsurance")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/cancer-insurances/:cancerId/sub-cancer-insurance/:companyId/:id/delete",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CancerInsurance/SubCancerInsurance/Delete")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/cancer-insurances/:cancerId/sub-cancer-insurance/:companyId/create",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CancerInsurance/SubCancerInsurance/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/cancer-insurances/:cancerId/sub-cancer-insurance/:companyId/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CancerInsurance/SubCancerInsurance/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/cancer-insurances/:cancerId/cancer-insurance-documents/:companyId/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CancerInsurance/CancerInsuranceDocuments/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance/cancer-insurances/:cancerId/sub-bulk-cancer-insurance/:companyId",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceProducts/CancerInsurance/SubCancerInsurance/BulkEdit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Bank Applications ===============
    {
        path: "/applications/allApplications",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE],
        component: lazy(() => import("src/views/BankApplications/AllApplications")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/allApplications/:id/edit",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE],
        component: lazy(() => import("src/views/BankApplications/AllApplications/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/applications/:status",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE],
        component: lazy(() => import("src/views/BankApplications/Applications")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/sales-Applications/:id",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/BankApplications/AllApplications/SalesApplications")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/applications/:status/:id",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE],
        component: lazy(() => import("src/views/BankApplications/Applications/Show")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/applications/:status/:id/review",
        role: [RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/BankApplications/Applications/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/applications/:status/:id/reviewBank",
        role: [RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE],
        component: lazy(() => import("src/views/BankApplications/Applications/EditBank")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Care Plus Applications ===============
    {
        path: "/care-plus/care-plus-applications",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.CARE_PLUS_ROLE, RolesEnum.MARKETING_ROLE],
        component: lazy(() => import("src/views/MedicalCardApplications")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/care-plus/care-plus-applications/:id",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.CARE_PLUS_ROLE, RolesEnum.MARKETING_ROLE],
        component: lazy(() => import("src/views/MedicalCardApplications/Show")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Insurance Products Applications ===============
    {
        path: "/insurance-applications/allApplications",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceApplications/AllApplications")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance-applications/:status",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceApplications/Applications")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance-applications/:status/:id",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE],
        component: lazy(() => import("src/views/InsuranceApplications/Applications/Show")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance-applications/:status/:id/review",
        role: [RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceApplications/Applications/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/insurance-applications/:status/:id/renewal",
        role: [RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceApplications/Applications/Renewal")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/sales-insurance-applications/:id",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/InsuranceApplications/AllApplications/SalesApplications")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Custom Insurance Products Applications ===============
    {
        path: "/custom-insurance-applications/allApplications",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/CustomInsuranceApplications/AllApplications")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/custom-insurance-applications/:status",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/CustomInsuranceApplications/Applications")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/custom-insurance-applications/:status/:id",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE],
        component: lazy(() => import("src/views/CustomInsuranceApplications/Applications/Show")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/custom-insurance-applications/:status/:id/review",
        role: [RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/CustomInsuranceApplications/Applications/Edit")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/custom-insurance-applications/:status/:id/renewal",
        role: [RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/CustomInsuranceApplications/Applications/Renewal")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/custom-sales-insurance-applications/:id",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.SUPER_VISOR_ROLE],
        component: lazy(() => import("src/views/CustomInsuranceApplications/AllApplications/SalesApplications")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Micro Finance Applications ===============
    {
        path: "/microfinance/all-applications",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.MICRO_FINANCE_ROLE],
        component: lazy(() => import("src/views/MicroFinance/Applications")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    {
        path: "/microfinance/all-applications/:id",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.MICRO_FINANCE_ROLE],
        component: lazy(() => import("src/views/MicroFinance/Applications/Show")),
        guard: AuthGuard,
        layout: DashboardLayout,
    },
    //  =============== Auth ===========================
    {
        path: "/auth/login",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE, RolesEnum.MICRO_FINANCE_ROLE],
        component: lazy(() => import("src/views/Auth/Login")),
        layout: AuthLayout,
        guard: GuestGuard,
    },
    //  =============== Error ===========================
    {
        path: "/403",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE, RolesEnum.MICRO_FINANCE_ROLE],
        component: lazy(() => import("src/views/Errors/NotAuthorized")),
        layout: ErrorLayout,
        guard: GuestGuard,
    },
    {
        path: "/500",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE, RolesEnum.MICRO_FINANCE_ROLE],
        component: lazy(() => import("src/views/Errors/ServerError")),
        layout: ErrorLayout,
        guard: GuestGuard,
    },
    {
        path: "*",
        role: [RolesEnum.SUPER_ADMIN_ROLE, RolesEnum.REVIEWER_ROLE, RolesEnum.SUPER_VISOR_ROLE, RolesEnum.BANK_PORTAL_ROLE, RolesEnum.DATA_ENTRY_ROLE, RolesEnum.MICRO_FINANCE_ROLE],
        component: lazy(() => import("src/views/Errors/NotFound")),
        layout: ErrorLayout,
        guard: GuestGuard,
    },
]

export const renderRoutes = (AllRoutes: any, basename: string) => (
    <BrowserRouter basename={basename}>
        <Suspense fallback={<LoadingScreen />}>
            <Routes>
                {AllRoutes?.map((route: any) => {
                    const RenderComponent = route?.component || Fragment
                    const Guard = route?.guard
                    const Layout = route?.layout || Fragment
                    const Role = route?.role
                    return (
                        <Route
                            key={route?.path}
                            path={route?.path}
                            element={
                                <Guard roles={Role}>
                                    <Layout>
                                        <RenderComponent />
                                    </Layout>
                                </Guard>
                            }
                        />
                    )
                })}
            </Routes>
        </Suspense>
    </BrowserRouter>
)

export default routes
