import { ThemeProvider } from "@material-ui/core"
import createTheme from "@material-ui/core/styles/createTheme"

const theme = createTheme({
    typography: {
        h1: {
            fontWeight: 500,
            fontSize: 35,
            letterSpacing: "-0.24px",
            color: "white",
        },
        h2: {
            fontWeight: 500,
            fontSize: 29,
            letterSpacing: "-0.24px",
        },
        h3: {
            fontWeight: "bold",
            fontSize: 24,
            letterSpacing: "-0.06px",
        },
        h4: {
            fontWeight: "bold",
            fontSize: 20,
            letterSpacing: "-0.06px",
        },
        h5: {
            fontWeight: "bold",
            fontSize: 16,
            letterSpacing: "-0.05px",
        },
        h6: {
            fontWeight: 500,
            fontSize: 14,
            letterSpacing: "-0.05px",
            color: "white",
        },
        overline: {
            fontWeight: 500,
            color: "white",
        },
        fontFamily: ["Cairo, sans-serif"].join(","),
    },
    palette: {
        primary: {
            main: "#1a90d2",
        },
        secondary: {
            main: "#f57e08",
        },
        error: {
            main: "#f5071c",
        },
        info: {
            main: "#fff",
        },
        warning: {
            main: "#f57d04",
        },
        success: {
            main: "#286f28",
        },
        background: {
            default: "#fff",
        },
    },
    transitions: {
        easing: {
            easeIn: "all .4s ease",
        },
    },
    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    overrides: {
        MuiDrawer: {
            paperAnchorDockedLeft: {
                borderRight: "none",
            },
        },
        MuiSvgIcon: {
            colorError: {
                color: "#f5071c !important",
            },
        },
    },
    props: {
        MuiButtonBase: {},
        MuiPopover: {
            elevation: 1,
        },
    },
})

export default function MaterialThemeProvider(props: { children: any }) {
    const { children } = props
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
