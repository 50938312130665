import useAuth from "src/hooks/useAuth"
import { Navigate } from "react-router-dom"
import { shallowEqual, useSelector } from "react-redux"
import { arrayIncludes } from "@material-ui/pickers/_helpers/utils"

const AuthGuard = (props: { children: any; roles: any }) => {
    const { children, roles } = props
    const { isAuthenticated } = useAuth()
    const { roleId } = useSelector((state: any) => ({ roleId: state?.auth?.user?.roleId }), shallowEqual)
    return <>{isAuthenticated ? arrayIncludes(roles, roleId) ? children : <Navigate to="/403" /> : <Navigate to="/auth/login" />}</>
}

export default AuthGuard
