export function removeCSSClass(ele: { className: string }, cls: string) {
    const reg = new RegExp(`(\\s|^)${cls}(\\s|$)`)
    ele.className = ele.className.replace(reg, " ")
}

export function addCSSClass(ele: { classList: { add: (arg0: any) => void } }, cls: any) {
    ele.classList.add(cls)
}

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname
