import { TOGGLE } from "src/redux/Navbar/action"
import { navbarAction } from "src/redux/Navbar/types"

export type Navbar = {
    open: boolean
}

const initialState: Navbar = {
    open: true,
}

const navbarReducer = (state: Navbar = initialState, action: navbarAction): Navbar => {
    switch (action.type) {
        case TOGGLE:
            return { ...state, open: !state.open }
        default:
            return state
    }
}

export default navbarReducer
