import axios from "src/utils/axios"

export async function getServices(path: string, headers: any = []) {
    const resault = await axios.get(`${path}`, headers)
    return resault
}

export async function postServices(path: string, body: any, headers: any = {}) {
    const resault = await axios.post(`${path}`, body, headers)
    return resault
}

export async function putServices(path: string, body: any, headers: any = {}) {
    const resault = await axios.put(`${path}`, body, headers)
    return resault
}

export async function deleteServices(path: string) {
    const resault = await axios.delete(`${path}`)
    return resault
}
