import { FC, memo } from "react"
import { toAbsoluteUrl } from "src/helpers/AssetsHelpers"
import { Link } from "react-router-dom"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

const AuthLayout: FC<{ children: FC }> = (props: { children: FC }) => {
    const { children } = props
    return (
        <Box display="flex" flexDirection="column" height="100vh">
            <Box display="flex" flexDirection="column" className="flex-lg-row flex-column-fluid" bgcolor="white" id="kt_login">
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" alignContent="center" p={10} className="bgi-size-cover bgi-no-repeat" style={{ backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-faydety.jpg")})` }}>
                    <Box display="flex" flexDirection="column">
                        <Link aria-label="Home Page" to="/">
                            <img loading="lazy" alt="Logo" height="100%" width={170} src={toAbsoluteUrl("/media/logos/faydety-logo.png")} />
                        </Link>
                        <Box mt={10} display="flex" flexDirection="column" justifyContent="center">
                            <Typography display="block" variant="h1">
                                Welcome to Faydety Dashboard
                            </Typography>
                        </Box>

                        <Box display="flex" flexDirection="column" justifyContent="between" mt={20}>
                            <Typography display="block" variant="h6">
                                V3.0.0
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {children}
            </Box>
        </Box>
    )
}

export default memo(AuthLayout)
