import { FC, memo } from "react"
import Box from "@material-ui/core/Box"

const ErrorLayout: FC<{ children: FC }> = (props: { children: FC }) => {
    const { children } = props
    return (
        <Box display="flex" flexDirection="column" height="100vh">
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" alignContent="center" p={10}>
                {children}
            </Box>
        </Box>
    )
}

export default memo(ErrorLayout)
